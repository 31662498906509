import * as React from 'react';
import {    
    Switch,    
    FormControlLabel,
    Checkbox,
    Box,
    Divider,
    Grid,
    Button,
} from '@mui/material';
import { Shipper, ShipperLocation, UserAllocationSummary } from '../store/Map'
import * as utils from '../store/Utils';
import { HtmlTooltip, Paper, GreenRadio, GreenCheckbox } from './StyledComponents'
import { useEffect } from 'react';


interface ShipperItemProps {
    shipper: Shipper;
    shipperLocation: ShipperLocation;    
    allocations_group_by_user_id_summary: UserAllocationSummary;
    handleSetVisible: (e: React.ChangeEvent<HTMLInputElement>, user_id: number) => void;
    handleShowViewStopsModal: (user_id: number) => void;
    handleSetShowRoute: (e: React.ChangeEvent<HTMLInputElement>, user_id: number) => void;
    handleAvailableToWorkConfirmedChanged: (e: React.ChangeEvent<HTMLInputElement>, user_id: number) => void;
}

const ShipperItem = React.memo<ShipperItemProps>((props) => {

    const [availabeToWorkConfirmed, setAvailabeToWorkConfirmed] = React.useState<boolean>(props.shipper.available_to_work_confirmed);

    useEffect(() => {
        setAvailabeToWorkConfirmed(props.shipper.available_to_work_confirmed);
    }, [props.shipper.available_to_work_confirmed]); 

    const handleAvailableToWorkConfirmedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        //optimistic update - cand sunt multi shipperi pe harta dureaza mult pana vine raspunsul si face rerender
        setAvailabeToWorkConfirmed(e.target.checked);
        props.handleAvailableToWorkConfirmedChanged(e, props.shipper.user_id);
    };

    return (
        <Paper elevation={24} >
            <Grid container spacing={0} 
                style={{textAlign: "center"}}>
                <Grid item xs={2}>
                    <GreenRadio
                        checked={props.shipperLocation.status === 1}
                        sx={{ margin: 0, padding: 0 }}
                        disabled={true}
                        name="radio-button-demo"
                    />
                </Grid>
                <Grid item xs={8}>
                    <strong className="mr-auto">{props.shipper.user_name}</strong>
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        color="primary"
                        sx={{ margin: 0, padding: 0 }}
                        checked={props.shipper.visible}
                        onChange={(e) => props.handleSetVisible(e, props.shipper.user_id)}
                    />
                </Grid>
            </Grid>
            <Box m={1}>
                <Divider />
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <strong>Name: </strong>{props.shipper.first_name + " " + props.shipper.last_name}
                </Grid>            

                {/* daca sunt multi livratori pe harta, afisarea vitezei, care se schimba cu o frecevnta mare, cauzeaza re-randarea listei de livratori ceea ce duce la penalizare de performanta (cu 100 merge ok, cu 500 merge sacadat)*/}            
                <Grid item xs={12}>
                    <strong>Speed: </strong>{utils.formatSpeed(props.shipperLocation.speed)}
                </Grid>

                <Grid item xs={12}>
                    <HtmlTooltip
                        placement="left"
                        title={
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        {props.allocations_group_by_user_id_summary ? String(props.allocations_group_by_user_id_summary.number_of_accepted_allocations) + " accepted / " + String(props.allocations_group_by_user_id_summary.number_of_new_allocations + " allocated") : "0 accepted / 0 allocated"}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    >
                        <span>
                            <strong>Stops: </strong>{
                                props.allocations_group_by_user_id_summary ? String(props.allocations_group_by_user_id_summary.number_of_accepted_allocations) + " / " + String(props.allocations_group_by_user_id_summary.number_of_new_allocations) : "0 / 0"
                            }
                            <Button color="primary" onClick={(e) => props.handleShowViewStopsModal(props.shipper.user_id)}>
                                View all
                            </Button>
                        </span>
                    </HtmlTooltip>
                </Grid>
            
                <Grid item xs={12}>
                    <strong>Available to work: </strong>
                    <GreenCheckbox
                        sx={{ margin: 0, padding: 0 }}
                        checked={props.shipper.available_to_work}
                        disabled={true}
                    />                    
                </Grid>            
                <Grid item xs={12}>
                    <strong>Distance: </strong>
                    {
                        props.allocations_group_by_user_id_summary ? props.allocations_group_by_user_id_summary.distance_total : "N/A"
                    }
                </Grid>
                <Grid item xs={12}>
                    <strong>Duration: </strong>
                    {
                        props.allocations_group_by_user_id_summary ? props.allocations_group_by_user_id_summary.duration_total : "N/A"
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.shipper.show_route}
                                onChange={(e) => props.handleSetShowRoute(e, props.shipper.user_id)}
                                name="checkShowRoute"
                                color="primary"
                            />
                        }
                        labelPlacement="end"
                        label="Show route"
                    />
                    <FormControlLabel                        
                        control={
                            <Switch
                                checked={availabeToWorkConfirmed}
                                onChange={(e) => handleAvailableToWorkConfirmedChanged(e)}
                                name="checkConfirmed"
                                color="primary"
                            />
                        }
                        labelPlacement="end"
                        label="Confirmed"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
},
    (prevProps, nextProps) => {

        var shippers_comp = utils.shallowCompare(prevProps.shipper, nextProps.shipper);
        var allocation_comp = utils.shallowCompare(prevProps.allocations_group_by_user_id_summary, nextProps.allocations_group_by_user_id_summary);
        var location_comp = (prevProps.shipperLocation.status === nextProps.shipperLocation.status);
        /*&& (prevProps.shipperLocation.speed == nextProps.shipperLocation.speed);*/

        return (shippers_comp && allocation_comp && location_comp);

    }
);



export default ShipperItem;
